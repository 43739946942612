<template>
    <div style="display: flex;justify-content: space-between">
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
        <div>
            <el-button type="success" :loading="loading" @click="downloadConfig">下载配置</el-button>
            <el-select v-model="label" :disabled="loading" style="width: 200px" @change="init">
                <el-option v-for="l in labels" :value="l" :label="`方案${l}`"></el-option>
            </el-select>
        </div>
    </div>
    <vue-draggable-next v-model="data" handle=".handle" :animation="300" @change="sort" style="margin-top: 10px">
        <template v-for="level in data">
            <div class="handle" style="display: inline-block;position: relative;">
                <el-image style="margin: 5px;height: 160px;cursor: move;"
                          :src="`${$root.cdn_url}/${level.thumbnail}`"></el-image>
                <div class="overlay" style="top: 5px;left: 5px;">
                    <b>ID:{{ level.nickname }}</b>
                </div>
                <div class="overlay" style="top: 5px;right: 5px">
                    <el-icon @click="remove(level)" style="cursor: pointer;">
                        <CloseBold/>
                    </el-icon>
                </div>
                <div class="overlay" style="bottom: 10px;left: 5px">
                    <el-icon style="font-size: 10px">
                        <Menu/>
                    </el-icon>
                    <b>{{ level.pieces }}</b>
                </div>
                <div class="overlay" style="bottom: 10px;right: 5px">
                    <el-icon style="cursor: pointer;" @click="download(level)">
                        <Download/>
                    </el-icon>
                </div>
            </div>
        </template>
    </vue-draggable-next>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <div>
            <el-select :disabled="loading" v-model="query.category" clearable filterable style="width: 200px"
                       placeholder="分类">
                <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                           :value="c.key"></el-option>
            </el-select>
            <el-select v-model="query.module" :disabled="loading" style="width: 100px" placeholder="模块">
                <el-option v-for="(v, k) in $root.image_module_enum" :label="v" :value="parseInt(k)"></el-option>
            </el-select>
            <el-select v-model="query.status" disabled style="width: 100px" placeholder="状态">
                <el-option v-for="(v, k) in $root.image_status_enum" :label="v" :value="parseInt(k)"></el-option>
            </el-select>
            <el-select :disabled="loading" v-model="query.video" clearable style="width: 100px" placeholder="激励">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
            </el-select>
            <el-input v-model="query.nickname" placeholder="假ID" style="width: 200px" :disabled="loading"></el-input>
            <el-button type="success" :loading="loading" @click="fetchData(1)">查询</el-button>
        </div>
        <el-table :data="image_list" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="假ID" prop="nickname" width="60"></el-table-column>
            <el-table-column label="缩略图" prop="thumbnail" width="100">
                <template #default="scope">
                    <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 80px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="玩法" prop="style" width="100">
                <template #default="scope">
                    {{ $root.image_style_enum[scope.row.style] }}
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="100">
                <template #default="scope">
                    <template v-for="key in scope.row.categories">
                        <template v-for="c in category_list">
                            <el-tag v-if="c.key === key">{{ c.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="视频" prop="video" width="100">
                <template #default="scope">
                    {{ scope.row.video ? '是' : '' }}
                </template>
            </el-table-column>
            <el-table-column label="作者" prop="author" width="100"></el-table-column>
            <el-table-column label="物品" prop="pieces" width="100"></el-table-column>
            <el-table-column label="上线时间" prop="online_date" width="150"></el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button type="primary" size="small" :disabled="loading || scope.row.disabled"
                               @click="submit(scope.row)"> {{ scope.row.disabled ? '已' : '' }}选择
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData" v-model:current-page="page"
                       :page-size="query.limit"></el-pagination>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset()">关闭</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from "vue-draggable-next";

export default {
    name: "Level",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, labels: [0, 1, 2, 3], label: 0, category_list: [],
            total: 0, image_list: [], cache: {}, page: 1,
            query: {
                limit: 100,
                status: 3,
                module: 1,
                skip: 0,
                nickname: null,
                video: null,
                category: null,
            },
        };
    },
    methods: {
        init() {
            this.loading = true;
            axios.get('/cms/v1/level', {params: {label: this.label}}).then(res => {
                this.data = res.data.data.levelList;
                this.loading = false;
            });
        },
        openDialog() {
            this.dialog_opened = true;
            this.fetchData();
        },
        fetchData() {
            const page = this.page;
            this.loading = true;
            const key = `${this.query.category || null}-${this.query.module || null}-${this.query.video || null}-${this.query.nickname || null}-${page}`;
            if (this.cache[key]) {
                this.image_list = this.cache[key];
                this.getDisabled();
                this.loading = false;
            } else {
                this.query.skip = page - 1;
                axios.get('/cms/v1/image', {params: this.query}).then(res => {
                    this.image_list = res.data.data.imageList;
                    this.total = res.data.data.total;
                    this.getDisabled();
                    this.loading = false;
                    this.cache[key] = this.image_list;
                });
            }
        },
        getDisabled() {
            this.image_list.forEach(image => {
                image.disabled = false;
                this.data.forEach(d => {
                    if (image.id === d.image_id) {
                        image.disabled = true;
                    }
                })
            });
        },
        submit(row) {
            this.loading = true;
            axios.post('/cms/v1/level', {image_id: row.id, label: this.label}).then(res => {
                this.data.push(res.data.data);
                this.reset();
            });
        },
        reset() {
            this.dialog_opened = this.loading = false;
        },
        sort() {
            this.loading = true;
            const sort = [];
            this.data.forEach(level => {
                sort.push(level.id)
            });
            axios.post('/cms/v1/sort/level', {sort: sort}).then(res => {
                this.loading = false;
            });
        },
        remove(level) {
            this.loading = true;
            axios.delete(`/cms/v1/level/${level.id}`).then(res => {
                this.data.splice(this.data.indexOf(level), 1);
                this.loading = false;
            });
        },
        download(level) {
            const link = document.createElement('a');
            link.href = `${this.$root.cdn_url}/${level.url}`;
            link.download = `${level.nickname}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadConfig() {
            this.loading = true;
            axios.get(`/cms/v1/config/level`, {params: {label: this.label}}).then(res => {
                const blob = new Blob([btoa(JSON.stringify(res.data.data))], {type: 'text/plain'});
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `方案${this.label}.json`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.loading = false;
            });
        }
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/category').then(res => {
                this.category_list = res.data.data.categoryList;
            }),
        ]).then(_ => {
            this.loading = false;
            this.init();
        })
    },
}
</script>

<style scoped>
.overlay {
    position: absolute;
    background-color: var(--el-color-danger-light-5);
    border-radius: 10px;
    line-height: 16px;
    padding: 0 5px;
    color: var(--el-text-color-primary);
    font-size: 14px;
}
</style>