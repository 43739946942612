<template>
    <div>
        <el-select :disabled="loading" v-model="query.category" clearable filterable style="width: 100px"
                   placeholder="分类">
            <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                       :value="c.key"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.module" clearable style="width: 80px" placeholder="模块">
            <el-option v-for="(v, k) in $root.image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.type" clearable style="width: 80px" placeholder="类型">
            <el-option v-for="(v, k) in $root.image_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.status" clearable style="width: 80px" placeholder="状态">
            <el-option v-for="(v, k) in $root.image_status_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.style" clearable style="width: 80px" placeholder="玩法">
            <el-option v-for="(v, k) in $root.image_style_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.author" clearable style="width: 80px" placeholder="作者"
                   filterable>
            <el-option v-for="a in author_list" :value="a"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.video" clearable style="width: 80px" placeholder="激励">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <date-range-picker v-model="query" start-placeholder="上传开始" end-placeholder="上传结束"
                           :disabled="loading" clearable value-start-name="upload_start"
                           value-end-name="upload_end" style="width: 200px"></date-range-picker>
        <date-range-picker v-model="query" start-placeholder="上线开始" end-placeholder="上线结束"
                           :disabled="loading" clearable style="width: 200px"></date-range-picker>
        <el-select v-model="query.online" :disabled="loading" style="width: 80px" placeholder="上线" clearable>
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
        </el-select>
        <el-input v-model="query.nickname" placeholder="假ID" style="width: 50px" :disabled="loading"></el-input>
        <el-button type="success" :loading="loading" @click="init">查询</el-button>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="假ID" prop="nickname" width="60"></el-table-column>
        <el-table-column label="缩略图" prop="thumbnail" width="100">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 80px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="120">
            <template #default="scope">
                <el-select v-model="scope.row.status" @change="updateRow(scope.row)" :disabled="!scope.row.url">
                    <el-option v-for="(v, k) in $root.image_status_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="玩法" prop="style" width="120">
            <template #default="scope">
                <el-select v-model="scope.row.style" @change="updateRow(scope.row)">
                    <el-option v-for="(v, k) in $root.image_style_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="模块" prop="module" width="100">
            <template #default="scope">
                {{ $root.image_module_enum[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="100">
            <template #default="scope">
                <template v-for="key in scope.row.categories">
                    <template v-for="c in category_list">
                        <el-tag v-if="c.key === key">{{ c.i18n_name.EN }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="视频" prop="video" width="100">
            <template #default="scope">
                {{ scope.row.video ? '是' : '' }}
            </template>
        </el-table-column>
        <el-table-column label="作者" prop="author" width="100"></el-table-column>
        <el-table-column label="物品" prop="pieces" width="100"></el-table-column>
        <el-table-column label="上传时间" prop="c_time" width="150">
            <template #default="scope">
                {{ timestampToDate(scope.row.c_time) }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="150"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button size="small" type="success" :disabled="loading" @click="$refs.piece.init(scope.row)">内容
                </el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑
                </el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="deleteRow(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData"
                   :page-size="query.limit"></el-pagination>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="分类" prop="categories">
                <el-select v-model="form.categories" :disabled="loading" multiple filterable>
                    <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                               :value="c.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模块" prop="module">
                <el-radio-group v-model="form.module" :disabled="loading">
                    <el-radio v-for="(v, k) in $root.image_module_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="类型" prop="type">
                <el-radio-group v-model="form.type" :disabled="loading">
                    <el-radio v-for="(v, k) in $root.image_type_enum" :label="parseInt(k)"
                              :disabled="parseInt(k) === 1">{{ v }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="玩法" prop="style">
                <el-radio-group v-model="form.style" :disabled="loading">
                    <el-radio v-for="(v, k) in $root.image_style_enum" :label="parseInt(k)">{{ v }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="激励视频" prop="video">
                <el-radio-group v-model="form.video" :disabled="loading">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="作者" prop="author" :rules="[{required: true, message: '请选择作者'}]">
                <el-select v-model="form.author" :disabled="loading" filterable allow-create>
                    <el-option v-for="a in author_list" :value="a"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="封面" prop="thumbnail">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('thumbnail')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.thumbnail" :src="`${$root.cdn_url}/${form.thumbnail}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading" :disabled="!editing">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="完成图" prop="resource" :rules="[{required: true, message: '请上传完成图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('resource')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}" :before-upload="handleBeforeUpload">
                    <el-image v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="底图" prop="underlay" :rules="[{required: true, message: '请上传底图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('underlay')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}" :before-upload="handleBeforeUpload">
                    <el-image v-if="form.underlay" :src="`${$root.cdn_url}/${form.underlay}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit" :disabled="[1, 3].includes(form.status)">确定</el-button>
        </template>
    </el-dialog>
    <piece ref="piece"></piece>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update, timestampToDate} from "../libs/utils";
import {ElMessageBox, ElMessage} from "element-plus";
import Piece from "../components/Piece";

export default {
    name: "Index",
    components: {DateRangePicker, Piece},
    data() {
        return {
            loading: false, data: [], editing: null, total: 0, dialog_opened: false, category_list: [], author_list: [],
            query: {
                category: null,
                module: null,
                status: null,
                start: null,
                end: null,
                upload_start: null,
                upload_end: null,
                skip: 0,
                limit: 100,
                video: null,
                nickname: null,
                author: null,
                online: null,
                type: null,
                style: null,
            },
            form: {
                categories: [],
                module: 1,
                resource: null,
                underlay: null,
                thumbnail: null,
                video: false,
                online_date: null,
                status: null,
                author: this.$root.user.name,
                type: 3,
                style: 1,
            },
        }
    },
    methods: {
        timestampToDate(timestamp) {
            return timestampToDate(timestamp);
        },
        init() {
            this.skip = 0;
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                    this.data = res.data.data.imageList;
                    this.total = res.data.data.total;
                }),
                axios.get(`/cms/v1/category`).then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
                axios.get(`/cms/v1/author`).then(res => {
                    this.author_list = res.data.data.authorList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        fetchData(page) {
            this.query.skip = page - 1;
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.editing = item;
                update(this.form, item);
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
            this.form.categories = [];
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            if (!this.author_list.includes(this.form.author)) {
                                this.author_list.push(this.form.author);
                            }
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/image`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            if (!this.author_list.includes(this.form.author)) {
                                this.author_list.push(this.form.author);
                            }
                            this.reset();
                        })
                    }
                }
            })
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(name) {
            return res => {
                this.form[name] = res.data.name;
                this.loading = false;
            }
        },
        deleteRow(row) {
            ElMessageBox.confirm(`确定删除该项吗？`, '提示', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/image/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            })
        },
        updateRow(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        handleBeforeUpload(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = e => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = _ => {
                        if (image.width === 2400 && image.height === 1600 && this.form.type !== 2 || this.form.type === 2 && image.width === 2048 && image.height === 2048) {
                            resolve(file);
                        } else {
                            ElMessage.error('图片尺寸不对');
                            reject();
                        }
                    }
                }
                reader.readAsDataURL(file);
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>