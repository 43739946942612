<template>
    <el-dialog v-model="opened" width="90%">
        <el-row>
            <el-col :span="14">
                <div>
                    <el-upload action="/cms/v1/generate/piece" :show-file-list="false" :onSuccess="handleLocation"
                               accept="image/*" :data="{image_id: image.id}" :onProgress="handleProgress"
                               :disabled="loading" :headers="{authorization: $root.authorization}" v-if="image">
                        <el-button type="primary" :loading="loading">上传定位图</el-button>
                    </el-upload>
                </div>
                <el-table :data="data" v-loading="loading" style="margin-top: 10px" :max-height="$root.height"
                          class="draggable" row-key="id" ref="table">
                    <el-table-column width="80" label="坐标">
                        <template #default="scope">
                            x: {{ scope.row.x }}<br/>
                            y: {{ scope.row.y }}<br/>
                        </template>
                    </el-table-column>
                    <el-table-column label="原图" width="100" prop="resource">
                        <template #default="scope">
                            <img :src="`${$root.cdn_url}/${scope.row.resource}`"
                                 style="max-height: 88px;max-width: 88px"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="遮罩图" width="100" prop="cover">
                        <template #default="scope">
                            <img :src="`${$root.cdn_url}/${scope.row.cover}`" style="max-height: 88px;max-width: 88px"
                                 v-if="scope.row.cover"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="预览图" width="100" prop="thumbnail">
                        <template #default="scope">
                            <div style="min-height: 88px;width: 76px" :ondrop="drop(scope.row)"
                                 :ondragover="allowDrop">
                                <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" style="width: 88px" lazy
                                          v-if="scope.row.thumbnail" draggable="true" :ondragstart="drag"
                                          :id="`${scope.row.id}:${scope.row.thumbnail}`"></el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="难度" width="100">
                        <template #default="scope">
                            <el-select v-model="scope.row.difficulty" :disabled="loading" @change="update(scope.row)">
                                <el-option label="简单" :value="1"></el-option>
                                <el-option label="中等" :value="2"></el-option>
                                <el-option label="困难" :value="3"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="操作">
                        <template #default="scope">
                            <el-button :disabled="loading" @click="openDialog(scope.row)" circle
                                       icon="Edit"></el-button>
                            <el-button type="danger" :disabled="loading" @click="remove(scope.row)" circle
                                       icon="Delete"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="10">
                <el-upload action="/cms/v1/resource" :onSuccess="handleThumbnail" accept="image/*" multiple
                           :headers="{authorization: $root.authorization}" :data="{prefix: 'thumbnail'}"
                           :show-file-list="false" :before-upload="beforeUpload">
                    <el-button type="primary" :disabled="data.length === 0" :loading="loading">批量预览图</el-button>
                </el-upload>
                <div v-loading="loading">
                    <template v-for="item in thumbnail_list">
                        <img :src="`${$root.cdn_url}/${item}`" :id="`:${item}`" style="width: 88px;" draggable="true"
                             :ondragstart="drag">
                    </template>
                </div>
                <div style="position: relative;cursor: pointer;margin-top: 10px">
                    <el-image :src="`${$root.cdn_url}/${image.underlay}`" lazy @load="loaded" ref="canvas"></el-image>
                    <template v-for="d in data">
                        <el-image v-if="(!d.selected && image.type !== 2 || d.selected && image.type === 2) && d.w && d.h && width && height" lazy
                                  style="position: absolute;pointer-events: none"
                                  :style="{top: `${d.y / height * client_height}px`, left: `${d.x / width * client_width}px`, width: `${d.w / width * client_width}px`, height: `${d.h / height * client_height}px`}"
                                  :src="`${$root.cdn_url}/${d.cover || d.resource}`"></el-image>
                    </template>
                </div>
                <vue-draggable-next v-model="data" class=".handle" :animation="300" @change="sort"
                                    style="margin-top: 10px;display: flex;flex-wrap: wrap">
                    <div v-for="d in data" style="position: relative;">
                        <el-image :src="`${$root.cdn_url}/${d.thumbnail}`" lazy class="handle"
                                  style="width: 60px;cursor: pointer;" @click="d.selected = !d.selected"></el-image>
                        <el-icon v-if="d.selected"
                                 style="position: absolute;top: 0;left: 0;color: var(--el-color-success)">
                            <CircleCheckFilled/>
                        </el-icon>
                    </div>
                </vue-draggable-next>
            </el-col>
        </el-row>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="close" :disabled="disabledGenerateUrl">提交
            </el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="X坐标" prop="x" :rules="[{required: true, message: '请输入x坐标'}]">
                <el-input-number v-model="form.x" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="Y坐标" prop="y" :rules="[{required: true, message: '请输入y坐标'}]">
                <el-input-number v-model="form.y" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="难度" prop="difficulty">
                <el-radio-group v-model="form.difficulty" :disabled="loading">
                    <el-radio :label="1">简单</el-radio>
                    <el-radio :label="2">中等</el-radio>
                    <el-radio :label="3">困难</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="游戏图" prop="resource" :rules="[{required: true, message: '请上传游戏图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('resource')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="遮罩图" prop="cover">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('cover')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.cover" :src="`${$root.cdn_url}/${form.cover}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="预览图" prop="resource" :rules="[{required: true, message: '请上传预览图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess('thumbnail')"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.thumbnail" :src="`${$root.cdn_url}/${form.thumbnail}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../libs/utils";
import {ElMessageBox} from "element-plus";
import {VueDraggableNext} from "vue-draggable-next";

export default {
    name: "Piece",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, opened: false, image: null, data: [], dialog_opened: false, editing: null,
            thumbnail_list: [], upload_count: 0, width: 0, height: 0, client_width: 0, client_height: 0,
            form: {
                image_id: null,
                x: null,
                y: null,
                thumbnail: null,
                resource: null,
                difficulty: null,
                cover: null,
            },
        }
    },
    methods: {
        drag(e) {
            e.dataTransfer.setData("Text", e.target.id);
        },
        allowDrop(e) {
            e.preventDefault();
        },
        drop(row) {
            return e => {
                e.preventDefault();
                const text = e.dataTransfer.getData("Text");
                const requests = [
                    axios.put(`/cms/v1/piece/${row.id}`, row)
                ];
                if (text) {
                    const s = text.split(":");
                    const image_id = s[0];
                    const thumbnail = s[1];
                    if (row.thumbnail) {
                        if (image_id) {
                            this.data.forEach(d => {
                                if (d.id === image_id) {
                                    d.thumbnail = row.thumbnail;
                                    requests.push(axios.put(`/cms/v1/piece/${d.id}`, d));
                                }
                            })
                        } else {
                            this.thumbnail_list.push(row.thumbnail);
                            if (this.thumbnail_list.indexOf(thumbnail) > -1)
                                this.thumbnail_list.splice(this.thumbnail_list.indexOf(thumbnail), 1);
                        }
                    } else {
                        if (this.thumbnail_list.indexOf(thumbnail) > -1)
                            this.thumbnail_list.splice(this.thumbnail_list.indexOf(thumbnail), 1);
                    }
                    row.thumbnail = thumbnail;
                }
                this.loading = true;
                axios.all(requests).then(_ => {
                    this.loading = false;
                });
            };
        },
        init(image) {
            this.opened = this.loading = true;
            this.image = image;
            this.thumbnail_list = [];
            this.upload_count = 0;
            axios.get(`/cms/v1/piece`, {params: {image_id: this.image.id}}).then(res => {
                this.data = res.data.data.pieceList;
                this.data.forEach(d => {
                    d.selected = false;
                    const image = new Image();
                    image.src = `${this.$root.cdn_url}/${d.resource}`;
                    image.onload = _ => {
                        d.w = image.width;
                        d.h = image.height;
                    }
                });
                this.loading = false;
            });
        },
        loaded() {
            const ele = this.$refs.canvas.$el;
            ele.removeEventListener('click', this.handleClick);
            ele.addEventListener('click', this.handleClick);
            const image = new Image();
            image.src = this.$refs.canvas.src;
            image.onload = _ => {
                this.width = image.width;
                this.height = image.height;
                this.client_width = ele.clientWidth;
                this.client_height = ele.clientHeight;
            }
        },
        handleClick(e) {
            const ele = this.$refs.canvas.$el;
            const rect = ele.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            const raw_x = x / ele.clientWidth * this.width;
            const raw_y = y / ele.clientHeight * this.height;
            this.data.forEach(d => {
                if (d.x <= raw_x && d.x + d.w > raw_x && d.y <= raw_y && d.y + d.h > raw_y) {
                    d.selected = true;
                }
            });
        },
        close() {
            this.loading = true;
            axios.post(`/cms/v1/generate/image`, {image_id: this.image.id}).then(res => {
                update(this.image, res.data.data);
                this.opened = this.loading = false;
            });
            this.image = null;
            this.width = this.height = this.client_height = this.client_width = 0;
            this.data = [];
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/piece/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        this.form.image_id = this.image.id;
                        axios.post(`/cms/v1/piece`, this.form).then(res => {
                            const row = res.data.data;
                            const image = new Image();
                            image.src = `${this.$root.cdn_url}/${row.resource}`;
                            image.onload = _ => {
                                row.w = image.width;
                                row.h = image.height;
                            }
                            row.selected = false;
                            this.data.push(row);
                            this.sort();
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/piece/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/piece/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        },
        sort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/piece`, {sort}).then(res => {
                this.loading = false;
            });
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(name) {
            return res => {
                this.form[name] = res.data.name;
                this.loading = false;
            }
        },
        handleLocation(res) {
            this.data = res.data.pieceList;
            this.loading = false;
        },
        handleThumbnail(res) {
            this.upload_count--;
            if (!this.thumbnail_list.includes(res.data.name)) {
                this.thumbnail_list.push(res.data.name);
            }
            if (this.upload_count === 0 && this.image.type !== 2) {
                axios.post(`/cms/v1/compare/thumbnail`, {
                    image_id: this.image.id,
                    thumbnail_list: this.thumbnail_list
                }).then(res => {
                    this.data = res.data.data.pieceList;
                    this.data.forEach(d => {
                        if (d.thumbnail && this.thumbnail_list.includes(d.thumbnail)) {
                            this.thumbnail_list.splice(this.thumbnail_list.indexOf(d.thumbnail), 1);
                        }
                    });
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        beforeUpload() {
            this.loading = true;
            this.upload_count++;
            return true;
        },
    },
    computed: {
        disabledGenerateUrl() {
            let valid = false;
            this.data.forEach(d => {
                if (!d.thumbnail) {
                    valid = true;
                }
            });
            return valid;
        }
    }
    ,
}
</script>

<style scoped>

</style>